@font-face {
    font-family: 'Cerebri Sans Pro Regular';
    src: local('Cerebri Sans Pro Regular'),
    url(./CerebriSansPro-Regular.otf);
}

@font-face {
    font-family: 'Cerebri Sans Pro Semi Bold';
    src: local('Cerebri Sans Pro Semi Bold'),
    url(./CerebriSansPro-SemiBold.otf);
}

@font-face {
    font-family: 'Cerebri Sans Pro Medium';
    src: local('Cerebri Sans Pro Medium'),
    url(./CerebriSansPro-Medium.otf);
}

@font-face {
    font-family: 'Cerebri Sans Pro Bold';
    src: local('Cerebri Sans Pro Bold'),
    url(./CerebriSansPro-Bold.otf);
}